<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: emailVerify.vue
Description: This file contains the form that handles the forgot password functionality useful for sending reset email to the user email
-->
<template>
  <el-row type="flex" justify="center">
    <el-col :span="spanValue">
      <card class="forgot-password-card generic-card">
        <template slot="title">
          <h2>{{ $t("Comn_forgot_ur_pwd") }}</h2>
        </template>
        <template slot="form">
          <el-alert
            v-if="ehm__unhandledErrorMessage"
            :title="ehm__unhandledErrorMessage"
            type="error"
            @close="ehm__handleAlertClose"
          ></el-alert>
          <er-form
            :model="user"
            ref="forgotPassword"
            v-loading="loading"
            element-loading-background="white"
            :element-loading-text="$t('Comn_auth_loading')"
            size="small"
            @submit.prevent.native="sumbitForgotPasswordDetails($event)"
          >
            <div class="form-field-label">
              {{ $t("Comn_to_reset_your_pwd_msg_only_email") }}
            </div>
            <er-form-item prop="email" :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)">
              <er-input v-model="user.email" :placeholder="$t('Usrs_enter_email_addr')" >
                <template slot="prepend">
                  <span class="material-icons-round icon--prefix"> email </span>
                </template>
              </er-input>
            </er-form-item>
          </er-form>
          <layout-toolbar justify="center">
            <er-button size="small" class="btn_back_to_login" @click="rdm__navigateToSignIn">
              {{ $t("Back") }} {{ $t("Comn_to") }} {{ $t("Harvest_log_in") }}
              </er-button>
            <er-button size="small" class="btn_submit" @click="sumbitForgotPasswordDetails($event)">
              {{ $t("Comn_get_password") }}
            </er-button>
          </layout-toolbar>
        </template>
      </card>
    </el-col>
  </el-row>
</template>
<script>
import User from "@/model/user.js";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
import card from "./card";
import {
  erButton,
  erForm,
  erInput,
  erFormItem
} from "@/components/base/basePondlogs.js";

export default {
  mixins: [errorHandlerMixin, authErrorKeyMapMixin, redirectsMixin],
  components: {
    erButton,
    erForm,
    erInput,
    erFormItem,
    card
  },
  data: function () {
    return {
      user: new User(),
      ehm__errMessagesObject: new User(),
      loading: false,
      formName: "forgotPassword"
    };
  },
  computed: {
    spanValue() {
      if (window.innerWidth <= 600) {
        return 24; // For small screens
      } else if (window.innerWidth <= 960) {
        return 14; // For medium screens
      } else if (window.innerWidth <= 1280) {
        return 10; // For large screens
      } else {
        return 10; // For extra-large screens
      }
    }
  },
  methods: {
    sumbitForgotPasswordDetails(event) {
      if (this.loading) {
        return;
      }

      // console.log("entered")
      // this.$refs.forgotPasswordForm.clearValidate()
      console.log("clicked", event);
      event.stopPropagation();
      this.ehm__unhandledErrorMessageV2 = null;

      this.loading = true;
      this.ehm__errMessagesObject = { email: "" };
      this.$store
        .dispatch("auth/forgotPassword", this.user)
        .then((response) => {
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("Comn_email_forgot_sucess_msg"),
            duration: 10000,
            type: "success"
          });
          this.$router.push("/sign-in");
        })
        .catch((err) => {
          this.ehm__errorMessages(err, false);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="scss">
.forgot-password-card {
  height: 100%;
  .general-err-msg {
    font-size: 13px;
    text-align: center;
    color: red;
  }
  .el-tag {
    font-size: 1.8vh;
  }
  .el-form {
    text-align: center;
  }
  .el-form-item .el-form-item__content {
    margin: 0px !important;
  }
  .user-properties-element {
    width: 80%;
    text-align: center;
    display: inline-block;
  }
  .btn-forgot-password {
    float: right;
  }
  .el-button {
    font-size: 14px;
  }
}
</style>
